const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const SET_THEME = 'SET_THEME';
const SET_NAVIGATION_DATA = 'SET_NAVIGATION_DATA';
const TOGGLE_MENU = 'TOGGLE_MENU';
const SEARCH = 'SEARCH';
const STUDY_DETAILS = 'STUDY_DETAILS';
const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';
const SET_SUPPLY_DATA = 'SET_SUPPLY_DATA';
const SET_ELEMENT_DATA ='SET_ELEMENT_DATA';
const SET_ROLECONFIG_DATA = 'SET_ROLECONFIG_DATA';
const SET_LANDING_PAGE = 'SET_LANDING_PAGE';
const SET_PARENT_DATA = 'SET_PARENT_DATA';
const SET_USERROLECONFIG_DATA = 'SET_USERROLECONFIG_DATA';
const SET_ALERT_DATA = 'SET_ALERT_DATA';
const SET_GROUP_DATA='SET_GROUP_DATA';
const SET_RULECONFIG_DATA="SET_RULECONFIG_DATA";
const SET_RULEPARAMETER_DATA="SET_RULEPARAMETER_DATA";
const SET_DELIVERY_METHOD="SET_DELIVERY_METHOD";
const SET_SELECTED_ROLE_DATA="SET_SELECTED_ROLE_DATA";
const SET_SELECTED_USER_DATA="SET_SELECTED_USER_DATA";

const SET_ROLE_GROUP_ACTIVETAB="SET_ROLE_GROUP_ACTIVETAB";

export {
    SHOW_LOADING,
    HIDE_LOADING,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SET_THEME,
    SET_NAVIGATION_DATA,
    TOGGLE_MENU,
    SEARCH,
    STUDY_DETAILS,
    SET_CURRENT_SCREEN,
    SET_SUPPLY_DATA,
    SET_ROLECONFIG_DATA,
    SET_PARENT_DATA,
    SET_USERROLECONFIG_DATA,
    SET_ELEMENT_DATA,
    SET_ALERT_DATA,
    SET_RULECONFIG_DATA,
    SET_RULEPARAMETER_DATA,
    SET_DELIVERY_METHOD,
    SET_SELECTED_ROLE_DATA,
    SET_SELECTED_USER_DATA,
    SET_LANDING_PAGE,
    SET_GROUP_DATA,
    SET_ROLE_GROUP_ACTIVETAB
}