import {
  TOGGLE_MENU,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SET_THEME,
  SET_NAVIGATION_DATA,
  SEARCH,
  STUDY_DETAILS,
  SET_CURRENT_SCREEN,
  SET_SUPPLY_DATA,
  SET_ROLECONFIG_DATA,
  SET_LANDING_PAGE,
  SET_USERROLECONFIG_DATA,
  SET_PARENT_DATA,
  SET_USERNAME,
  SET_ELEMENT_DATA,
  SET_ALERT_DATA,
  SET_RULECONFIG_DATA,
  SET_RULEPARAMETER_DATA,
  SET_GROUP_DATA,
  SET_DELIVERY_METHOD,
  SET_SELECTED_ROLE_DATA,
  SET_SELECTED_USER_DATA,
  SET_ROLE_GROUP_ACTIVETAB

} from "../types/types";
import { getTheme } from "../../utils/commonUtils";

const initialState = {
  showLoading: false,
  notification: {
    type: "",
    message: "",
    description: "",
    status: false,
  },
  theme: getTheme(),
  navigationData: {},
  elementData: [],
  isMenuCollapsed: true,
  search: null,
  group: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuCollapsed: !state.isMenuCollapsed,
      };
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case HIDE_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          status: true,
          ...action.payload,
        },
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          status: false,
        },
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_GROUP_DATA:
      return {
        ...state,
        group: action.payload
      };
    case SET_NAVIGATION_DATA:
      return {
        ...state,
        navigationData: action.payload,
      };
    case SET_ELEMENT_DATA:
      return {
        ...state,
        elementData: action.payload,
      };
    case STUDY_DETAILS:
      return {
        ...state,
        currentStudyDetails: action.payload,
      };
    case SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: action.payload,
      };
    case SET_SUPPLY_DATA:
      return {
        ...state,
        supplyDatas: action.payload,
      };
    case SET_ROLECONFIG_DATA:
      return {
        ...state,
        roleConfigData: action.payload,
      };
    case SET_LANDING_PAGE:
      return {
        ...state,
        landingPages: action.payload,
      };
    case SET_PARENT_DATA:
      return {
        ...state,
        parentData: action.payload,
      };
    case SET_USERROLECONFIG_DATA:
      return {
        ...state,
        userRoleConfigData: action.payload,
      };
    case SET_ALERT_DATA:
      return {
        ...state,
        alertSeletedRecord: action.payload,
      };
    case SET_RULECONFIG_DATA:
      return {
        ...state,
        ruleConfigData: action.payload,
      };
    case SET_RULEPARAMETER_DATA:
      return {
        ...state,
        ruleParameterContent: action.payload,
      };
    case SET_DELIVERY_METHOD:
      return {
        ...state,
        deliveryContent: action.payload,
      };
    case SET_SELECTED_ROLE_DATA:
      return {
        ...state,
        selectedRoleData: action.payload,
      };
    case SET_SELECTED_USER_DATA:
      return {
        ...state,
        selectedUserData: action.payload,
      };
    case SET_ROLE_GROUP_ACTIVETAB:
      return {
        ...state,
        activeTabKey: action.payload,
      };

    default:
      return state;
  }
};
